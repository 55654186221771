//libraries
import React, { Fragment } from "react";
//components

//css
import "./styles/copyright.css";

function CopyRight(props) {
	return (
		<Fragment>
			<div className="copyRight-wrap">
				<p className="copyRight-desc">
					©2023 Dream Carz Leasing Pte.Ltd.
				</p>
			</div>
		</Fragment>
	);
}

export default CopyRight;
