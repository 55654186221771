//libraries
import React, { Fragment } from "react";
//components
import { Row, Col } from "react-bootstrap";
//css
import "./styles/companyinfo.css";

function CompanyInfo(props) {
  return (
    <Fragment>
      <div className="footer-company-info-wrap">
        <Row className="footer-ci-row">
          <Col md={3} className="footer-ci-col">
            <h1 className="footer-ci-col-title">CONTACT INFO</h1>
            <p className="footer-ci-col-desc">
              <span>
                <i className="fas fa-building" />
                Dream Carz Leasing Pte. Ltd.
              </span>
              <span>
                <i className="fas fa-map-marked" />
                8 Kaki Bukit Avenue 4,<br/> Premier @ Kaki Bukit, #05-27,<br/>
                Singapore, Singapore
              </span>
              <span>
                <i className="fas fa-phone" />
                +65 9118 9972
              </span>
              <span>
                <i className="fas fa-envelope" />
                dreamcarzleasing@gmail.com
              </span>
            </p>
          </Col>
          <Col md={3} className="footer-ci-col">
            <h1 className="footer-ci-col-title">OUR COMPANIES</h1>
            <div className="footer-ci-col-link-wrap">
              <p> Dream Carz Leasing Pte. Ltd. </p>
              <p> A-Tec Automotive Pte. Ltd.<br/>(East Branch) </p>
              <p> A-Tec Motorz Pte. Ltd.<br/>(North Branch) </p>
              <p> A-Tec Autohub Pte. Ltd.<br/>(West Branch) </p>
            </div>
          </Col>
          <Col md={3} className="footer-ci-col">
            <h1 className="footer-ci-col-title">OPEN HOURS</h1>
            <h4 className="footer-ci-col-subtitle">Service Department</h4>
            <p className="footer-ci-col-desc" style={{ margin: "15px 0px" }}>
              Mon-Sat : 9:00am - 6:30pm <br />
              Closed on Sunday and Public Holidays
            </p>
            <h4 className="footer-ci-col-subtitle">Sales Department</h4>
            <p className="footer-ci-col-desc" style={{ margin: "15px 0px" }}>
              Mon-Sat : 9:00am - 6:30pm <br />
              Closed on Sunday and Public Holidays
            </p>
          </Col>
          <Col md={3} className="footer-ci-col">
            <h1 className="footer-ci-col-title">OTHERS</h1>
            <div className="footer-ci-col-link-wrap">
                <a href="/privacy-policy"> Privacy Policy</a>
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default CompanyInfo;
