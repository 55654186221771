//libraries
import { Fragment } from "react";
//components
import AppEc from "./Layouts/Body";
//css

function App(props) {
  return (
    <Fragment>
      <AppEc />
    </Fragment>
  );
}

export default App;
