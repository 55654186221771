//libraries
import React, { Fragment } from "react";
import $ from "jquery";
import { connect } from "react-redux";
//components
import { Nav, Navbar, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { setLogin, setLogout } from "../../../reducers/userActionsStore";
//css
import "../components/styles/navcomponents.css";

function NavComponents(props) {
  const isLoggined = props.getLogin;

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $(".navbar").addClass("navbar-scroll");
    } else {
      $(".navbar").removeClass("navbar-scroll");
    }
  });

  const handleLogout = (e) => {
    e.preventDefault();
    props.dispatch(setLogin(false));
    props.dispatch(setLogout());
    window.location.assign("/login");
  };

  return (
    <Fragment>
      <Navbar fixed="top" id="navbar" collapseOnSelect expand="lg">
        <Container>
          <NavLink className="navbar-brand" to="/home">
            <div className="company-logo"></div>
          </NavLink>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <NavLink className="nav-link" to="/home">
                <span className="profile-css-span"> Home </span>
              </NavLink>
              <NavLink className="nav-link" to="/vehicles">
                <span className="profile-css-span"> Vehicles </span>
              </NavLink>
              <NavLink className="nav-link" to="/contact-us">
                <span className="profile-css-span"> Contact Us </span>
              </NavLink>
              {isLoggined ? (
                <>
                  <NavLink className="nav-link" to="/booking">
                    <span className="profile-css-span"> Booking </span>
                  </NavLink>
                  <NavLink className="nav-link" to="/profile">
                    <span className="profile-css-span"> Profile </span>
                  </NavLink>
                  <NavLink
                    className="nav-link"
                    to={"/login"}
                    onClick={(e) => handleLogout(e)}
                  >
                    <i
                      className="fas fa-sign-out-alt"
                      style={{ color: "#dc3545" }}
                    ></i>
                    <span className="profile-css-span"> Log out</span>
                  </NavLink>
                </>
              ) : (
                <NavLink className="nav-link" to="/login">
                  <span className="profile-css-span"> Login </span>
                </NavLink>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
}

const mapStatetoProps = (state) => ({
  getLogin: state.UserActions.GetLogin,
});

export default connect(mapStatetoProps)(NavComponents);
