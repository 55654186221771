//libraries
import React, { Fragment, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
//components
import Header from "../Header/index";
import Footer from "../Footer/index";
//css
import "./components/styles/index.css";

//importing pages
const Register = lazy(() => import("../../Pages/Auth/Register"));
const Login = lazy(() => import("../../Pages/Auth/Login"));
const ForgotPassword = lazy(() => import("../../Pages/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../../Pages/Auth/ResetPassword"));
const ChangePassword = lazy(() => import("../../Pages/Auth/ChangePassword"));
const Profile = lazy(() => import("../../Pages/Account/sub/Profile"));
const EditProfile = lazy(() =>
	import("../../Pages/Account/components/EditProfile")
);

const Vehicles = lazy(() => import("../../Pages/Vehicles/sub/Vehicle"));
const VehicleDetail = lazy(() =>
	import("../../Pages/Vehicles/sub/VehicleDetail")
);
const PageNotFound = lazy(() => import("../../Pages/404"));

const Booking = lazy(() => import("../../Pages/Booking/sub/Booking"));
const UploadDocument = lazy(() =>
	import("../../Pages/Booking/components/UploadDocument")
);

const Policy = lazy(() => import("../../Pages/Policy/Policy"));
const ContactUs = lazy(() => import("../../Pages/Contact/Contact"));
const Home = lazy(() => import("../../Pages/Home/Home"));

function AppEc() {
	return (
		<Fragment>
			<Suspense
				fallback={
					<div>
						<p style={{ textAlign: "center", marginTop: "10px" }}>
							Please wait while we are loading the page
						</p>
					</div>
				}
			>
				<Header />
				<div style={{ marginTop: "95px" }}>
					<Switch>
						<Route exact path="/" component={Home} />
						<Route
							strict
							path="/vehicles/:id"
							component={VehicleDetail}
						/>
						<Route strict path="/vehicles" component={Vehicles} />
						<Route strict path="/booking" component={Booking} />
						<Route
							strict
							path="/upload-document/:id"
							component={UploadDocument}
						/>
						<Route strict path="/register" component={Register} />
						<Route strict path="/login" component={Login} />
						<Route
							strict
							path="/forgot-password"
							component={ForgotPassword}
						/>
						<Route
							strict
							path="/reset-password"
							component={ResetPassword}
						/>
						<Route
							strict
							path="/change-password"
							component={ChangePassword}
						/>
						<Route strict path="/profile" component={Profile} />
						<Route
							strict
							path="/edit-profile"
							component={EditProfile}
						/>

						<Route
							strict
							path="/privacy-policy"
							component={Policy}
						/>
                        <Route strict path="/contact-us" component={ContactUs}/>
                        <Route strict path="/home" component={Home}/>
						<Route strict path="*" component={PageNotFound} />
					</Switch>
				</div>
				<Footer />
			</Suspense>
		</Fragment>
	);
}

export default AppEc;
